import React from 'react';
import Sidenav from '../compoments/Sidenav';
import Navbar from "../compoments/Navbar";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function About() {
  return (
    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <h1>About</h1>
          <Typography sx={{ marginBottom: 2 }}>
            Welcome About
          </Typography>
        </Box>
      </Box>

    </>

  )
}
